import cn from 'classnames';
import React, { useState } from 'react';
import { TooltipPosition, TooltipSize } from './types';

interface Props {
  size?: TooltipSize;
  position?: TooltipPosition;
}

export const Tooltip: React.FC<Props> = props => {
  const { children, size = 'md', position = 'right' } = props;
  const classNames = cn('tooltip', `tooltip-${size}`, `tooltip-${position}`);
  const [expanded, setExpanded] = useState(false);

  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
  };

  const onMouseOverEvent = () => {
    setExpanded(true);
  };

  const onMouseOutEvent = () => {
    setExpanded(false);
  };

  return (
    <a
      href="/"
      role="tooltip"
      aria-haspopup="true"
      aria-label="More Information"
      onMouseOver={onMouseOverEvent}
      onMouseOut={onMouseOutEvent}
      onFocus={onMouseOverEvent}
      onBlur={onMouseOutEvent}
      aria-expanded={expanded}
      className={classNames}
      onClick={onClick}>
      {children}
    </a>
  );
};

export const TooltipTrigger: React.FC = props => <>{props.children}</>;

export const TooltipContent: React.FC = props => (
  <span className="tooltip-content">{props.children}</span>
);
