import { CspFeatureFlagAdjustedConfig, getAdjustedConfiguration } from './config';
import { CspFeatureFlagService } from './service';
import {
    CspFeatureFlagInvalidSdkKeyError,
    CspFeatureFlagInvalidTokenError,
} from './errors';
import {
    CspFeatureFlagMultivariateData,
    CspFeatureFlagConfig,
    CspFeatureFlagInitializationResult,
    CspFeatureFlagsEvaluationResponse,
    CspFeatureFlagEvaluation,
    CspFeatureFlagEvaluationEvent,
} from './model';

const ANALYTICS_BUFFER_SEND_THRESHOLD = 300;
const ANALYTICS_SEND_INTERVAL_MS = 60000;

export class CspFeatureFlagClient {
    private flagIdToFlagStateMap: { [flagId: string]: CspFeatureFlagEvaluation } = {};
    private correlationId: string;
    private analyticsEventBuffer: CspFeatureFlagEvaluationEvent[] = [];
    private analyticsIntervalHandle: any;

    constructor(private config?: CspFeatureFlagAdjustedConfig, private service?: CspFeatureFlagService) {
        if (!this.config || !this.service || this.config && this.config.disableAnalytics) {
            return;
        }

        // When user closes the browser window, or navigates away flush the analytics buffer
        window.addEventListener('beforeunload', () => {
            if (this.analyticsIntervalHandle) {
                window.clearInterval(this.analyticsIntervalHandle);
            }
            this.flushAnalyticsBuffer();
        });

        // When user switches to another tab or window, flush the analytics buffer
        window.addEventListener('blur', () => {
            this.flushAnalyticsBuffer();
        });

        const setupFlusher = () => {
            this.analyticsIntervalHandle = window.setInterval(() => {
                this.flushAnalyticsBuffer();
            }, ANALYTICS_SEND_INTERVAL_MS);
        };

        // If Zone.js is loaded, run in the root zone to avoid running in the Angular zone.
        if ((window as any).Zone) {
            (window as any).Zone.root.run(() => {
                setupFlusher();
            });
        } else {
            setupFlusher();
        }
    }

    static initialize(userConfig: CspFeatureFlagConfig): Promise<CspFeatureFlagInitializationResult> {
        return new Promise((resolve, reject) => {
            try {
                const config = getAdjustedConfiguration(userConfig);
                const service = new CspFeatureFlagService(config);
                const instance = new CspFeatureFlagClient(config, service);

                service.load()
                    .then((results: CspFeatureFlagsEvaluationResponse) => {
                        const flags: any = {};

                        for (const result of results.evaluationResults) {
                            instance.flagIdToFlagStateMap[result.id] = result;
                            flags[result.id] = result.booleanEvaluationResult;
                        }

                        instance.correlationId = results.evalCorrelationId;

                        resolve({
                            client: instance,
                            flags: flags,
                        });
                    },
                    (error: Error) => {
                        resolve({
                            client: instance,
                            error: error
                        });
                    });

            } catch(error) {
                const instance = new CspFeatureFlagClient();
                resolve({
                    client: instance,
                    error: error
                });
            }
        });
    }

    /*
    ----------------------------------------------------------
    |                Client evaluation methods               |
    ----------------------------------------------------------
     */

    /**
     * Check if the flag is enable or disable
     *
     *  - If a non existing flag ID is passed, the flag will be evaluated as false
     *  - If a non boolean typed flag is passed, the flag will be evaluated as false
     *
     * Important: Should be used to evaluate only boolean typed flags
     * (false will be returned if non boolean flag is passed)
     *
     * @param flagId   the flag id
     */
    public evaluate(flagId: string): boolean {
        const flagEvaluationResult = this.flagIdToFlagStateMap[flagId];

        if (!flagEvaluationResult) {
            return false;
        }

        const toggleType = flagEvaluationResult.toggleType ?
            flagEvaluationResult.toggleType.toLowerCase() : '';

        if ('boolean' != toggleType) {
            return false;
        }

        this.logEvaluation(flagEvaluationResult);

        return flagEvaluationResult.booleanEvaluationResult || false;
    }

    /**
     * Get the flag current variation value
     *
     * - If a non existing flag ID is passed, null response will be returned
     * - If a non multivariate typed flag is passed, null response will be returned
     *
     * Important: Should be used to evaluate only multivariate typed flags
     * (null will be returned if non multivariate flag is passed)
     *
     * @param flagId   the flag id
     */
    public evaluateMultivariate(flagId: string): string {
        const res = this.evaluateMultivariateWithMetadata(flagId);
        return res ? res.value : null;
    }

    /**
     * Get the flag current variation and its defined metadata
     *
     * - If a non existing flag ID is passed, null response will be returned and empty metadata
     * - If a non multivariate typed flag is passed, null response will be returned and empty metadata
     *
     * Important: Should be used to evaluate only multivariate typed flags
     * (null will be returned if non multivariate flag is passed)
     *
     * @param flagId   the flag id
     */
    public evaluateMultivariateWithMetadata(flagId: string): CspFeatureFlagMultivariateData {
        const flagEvaluationResult = this.flagIdToFlagStateMap[flagId];

        if (!flagEvaluationResult) {
            return null;
        }

        const toggleType = flagEvaluationResult.toggleType ?
            flagEvaluationResult.toggleType.toLowerCase() : '';

        if (toggleType !== 'multivariate') {
            return null;
        }

        this.logEvaluation(flagEvaluationResult);

        return flagEvaluationResult.multivariateEvaluationResult || null;
    }

    private flushAnalyticsBuffer() {
        if (this.analyticsEventBuffer.length === 0) {
            return;
        }

        // take a copy in case it fails and we need to put them back.
        const eventsToSend = this.analyticsEventBuffer.splice(0, ANALYTICS_BUFFER_SEND_THRESHOLD);

        this.service.sendAnalytics(eventsToSend).then(() => {
            // we're done ...
        }, () => {
            // failure, stick the payload back into the buffer so we try again next time
            this.analyticsEventBuffer.splice(0, 0, ...eventsToSend);
        });
    }

    private logEvaluation(flag: CspFeatureFlagEvaluation) {
        if (!this.correlationId || this.config.disableAnalytics) {
            return;
        }

        // This handles the case where we have a pathology of the same flag evaluation happening
        // very frequently. We don't ever include an event for a flag that already has an event
        // pending flushing.
        const haveExistingEvent = this.analyticsEventBuffer.some((event: CspFeatureFlagEvaluationEvent) => {
            return event.flag.id === flag.id;
        });

        if (haveExistingEvent) {
            return;
        }

        this.analyticsEventBuffer.push({
            evalCorrelationId: this.correlationId,
            evaluationTime: Date.now(),
            flag: {
                id: flag.id,
                toggleType: flag.toggleType
            },
        });

        if (this.analyticsEventBuffer.length >= ANALYTICS_BUFFER_SEND_THRESHOLD) {
            this.flushAnalyticsBuffer();
        }
    }
}
