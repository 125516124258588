import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import './TreeView.css';
import { TreeState } from './types';

interface TreeNodeProps {
  expandable: boolean;
  state?: TreeState;
  content: React.ReactNode;
  onToggle?: (state: TreeState) => void;
}

interface TreeNodeItemProps {
  routeTo?: string;
  active?: boolean;
  clickable?: boolean;
  onClick?: () => void;
}

export const TreeNode: React.FC<TreeNodeProps> = props => {
  const {
    children,
    content,
    expandable,
    state = 'collapsed',
    onToggle
  } = props;
  const loading = state === 'loading';
  const expanded = state === 'expanded';

  const onNodeToggle = () => {
    if (onToggle) {
      onToggle(state);
    }
  };

  const renderToggleButton = () => {
    return (
      expandable &&
      (!loading ? (
        <button
          type="button"
          className="clr-treenode-caret"
          onClick={onNodeToggle}
          aria-expanded={expanded}>
          <clr-icon
            shape="caret"
            class="clr-treenode-caret-icon"
            size="10"
            dir={expanded ? 'down' : 'right'}
          />
        </button>
      ) : (
        <div className="clr-treenode-spinner-container">
          <span className="clr-treenode-spinner spinner" />
        </div>
      ))
    );
  };

  return (
    <div className="clr-tree-node">
      <div className="clr-tree-node-content-container">
        {renderToggleButton()}
        <div className="clr-treenode-content">{content}</div>
      </div>
      {expanded && <div className="clr-treenode-children">{children}</div>}
    </div>
  );
};

export const TreeNodeItem: React.FC<TreeNodeItemProps> = props => {
  const { children, routeTo, active, onClick, clickable } = props;
  const classNames = cn(
    { 'clr-treenode-link': clickable },
    {
      active
    }
  );
  return routeTo ? (
    <Link className={classNames} to={routeTo} onClick={onClick}>
      {children}
    </Link>
  ) : clickable ? (
    <button className={classNames} onClick={onClick}>
      {children}
    </button>
  ) : (
    <span className="crc-treenode-item">{children}</span>
  );
};
