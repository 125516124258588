import cn from 'classnames';
import React from 'react';
import './Spinner.css';
interface Props {
  size?: SpinnerSize;
  type?: SpinnerType;
  inline?: boolean;
}

type SpinnerType = 'inverse';
type SpinnerSize = 'sm' | 'md' | 'lg';
const Spinner: React.FC<Props> = props => {
  const { children, size, type, inline } = props;
  const spinnerClass = cn('spinner', {
    [`spinner-${size}`]: size,
    [`spinner-${type}`]: type,
    'spinner-inline': inline
  });

  return (
    <div className="crc-spinner-wrapper">
      <span className={spinnerClass} />
      {children ? <span className="crc-spinner-text">{children}</span> : null}
    </div>
  );
};

export default Spinner;
