import { CspFeatureFlagAdjustedConfig } from './config';
import {
    CspFeatureFlagInvalidSdkKeyError,
    CspFeatureFlagInvalidTokenError,
    CspFeatureFlagLoadFailureError,
} from './errors';
import {
    CspFeatureFlagsEvaluationAnalyticsPacket,
    CspFeatureFlagEvaluationEvent,
    CspFeatureFlagsEvaluationResponse,
    CspFeatureFlagConfig,
} from './model';

/*
 * API Properties
 */
const API_PREFIX = '/csp/gateway/ff-service/api/sdk';
const EVALUATION_API_PATH = `${API_PREFIX}/public-flags`;
const ANALYTICS_API_PATH = `${API_PREFIX}/analytics/client-side/evaluations`;
const VERSION_TOKEN = '%%CSP_FF_SDK_VERSION%%';
const CSP_FF_SDK_VERSION_TOKEN = `JavaScriptFeatureFlagClient/${VERSION_TOKEN}`;

interface CspFeatureFlagHttpError {
    statusCode: number;
    error: string;
}

export class CspFeatureFlagService {
    private retryCount: number = 1;

    constructor(public config: CspFeatureFlagAdjustedConfig) {}

    public load(): Promise<CspFeatureFlagsEvaluationResponse> {
        const headers: any = {
            'Content-Type': 'application/json'
        };

        const body: any = {
            sdkVersion: CSP_FF_SDK_VERSION_TOKEN,
            sdkKey: this.config.sdkKeyValue,
            token: this.config.token,
            flagIds: this.config.flagIds,
            component: {
                name: this.config.componentName,
            },
        };

        // Version is not set, could be during testing or somehow someone
        // got access to a pre-release version. Override the version to just
        // 'test'.
        if (body.sdkVersion.indexOf('%%') !== -1) {
            body.sdkVersion = body.sdkVersion.replace(VERSION_TOKEN, 'test');
        }

        let url = `${this.config.cspURL}${EVALUATION_API_PATH}`;

        return this.http('POST',
                url,
                headers,
                body,
                this.config.apiTimeoutInMilliseconds)
            .then((flags: string) => {
                return JSON.parse(flags);
            },
            (err: CspFeatureFlagHttpError) => {
                // Handle invalid SDK key
                if (err.statusCode && err.statusCode == 403) {
                    throw new CspFeatureFlagInvalidSdkKeyError();
                }

                // Handle invalid token
                if (err.statusCode && err.statusCode == 400 && err.error) {
                    const cspError = JSON.parse(err.error);
                    if (cspError?.cspErrorCode && cspError.cspErrorCode.endsWith('590.300')) {
                        throw new CspFeatureFlagInvalidTokenError();
                    }
                }

                // Perform the specified retry policy
                if (err.statusCode && err.statusCode >= 500
                        && this.retryCount < this.config.maxAttempts) {
                    this.retryCount++;
                    return this.load();
                }

                console.error(err);
                throw new CspFeatureFlagLoadFailureError();
            });
    }

    public sendAnalytics(events: CspFeatureFlagEvaluationEvent[]) {
        const headers: any = {
            'Content-Type': 'application/json'
        };
        const url = this.config.cspURL + ANALYTICS_API_PATH;
        const body: CspFeatureFlagsEvaluationAnalyticsPacket = {
            sdkKey: this.config.sdkKeyValue,
            sdkVersion: CSP_FF_SDK_VERSION_TOKEN,
            component: {
                name: this.config.componentName,
            },
            evaluations: events,
        };

        return this.http('POST', url, headers, body);
    }

    /**
     * Small wrapper for XmlHttpRequest with timeout capability
     */
    http(method: string,
         url: string,
         headers: { [key: string]: string } = {},
         body?: any,
         timeout?: number): Promise<CspFeatureFlagHttpError | string> {
        return new Promise((resolve, reject) => {
            let timeoutHandle: any;
            const xhr = new XMLHttpRequest();

            xhr.open(method, url, true);

            Object.keys(headers).forEach((key) => {
                xhr.setRequestHeader(key, headers[key])
            });

            if (timeout) {
                timeoutHandle = setTimeout(() => {
                    xhr.abort();

                    reject({
                        status: 500,
                        response: "Operation timed out"
                    });
                }, timeout);
            }

            if (body) {
                xhr.send(JSON.stringify(body));
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    clearTimeout(timeoutHandle);

                    if (xhr.status >= 200 && xhr.status < 300) {
                        resolve(xhr.responseText);
                    } else {
                        reject({
                            statusCode: xhr.status,
                            error: xhr.responseText,
                        });
                    }
                }
            }
        });
    }
}
