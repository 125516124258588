import { CspFeatureFlagInvalidSdkKeyError } from './errors';

export function verifyAndDecodeSdkKey(sdkKey: string): string[] {
    // window.atob can throw
    try {
        const decodedApiKey: string = window.atob(sdkKey);
        const keyParts: string[] = decodedApiKey.split('||');

        if (keyParts.length != 2 || !keyParts[1]) {
            throw new CspFeatureFlagInvalidSdkKeyError();
        }

        return [keyParts[0], keyParts[1]];
    } catch (error) {
        throw new CspFeatureFlagInvalidSdkKeyError();
    }
}
