import { nanoid } from 'nanoid';

// min and max included
export const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const randomId = (prefix: string) =>
  `${prefix}-${randomIntFromInterval(1000000000, 10000000000000000)}`;

/**
 * Returns an id generator function. If `defaultValue` is supplied
 * the generator function always returns the same value otherwise it
 * returns a new id on each invocation.
 * @param defaultValue
 */
export const idGenerator = (defaultValue?: string) => () =>
  defaultValue === undefined ? `id-${nanoid()}` : defaultValue;

export const noop = () => Object.create(null);
