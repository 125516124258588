import cn from 'classnames';
import React, { useState } from 'react';
import { idGenerator } from '../common/Utils';
import { TooltipPosition, TooltipSize } from '../tooltip';
import Label from './Label';

interface Props {
  name?: string;
  value?: string;
  label?: string;
  required?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  placeholder?: string;
  helpText?: string | JSX.Element;
  helpTextPosition?: 'after-label' | 'below-field';
  helperTextToolTipConfig?: {
    size?: TooltipSize;
    position?: TooltipPosition;
  };
  error?: boolean;
  errorMessage?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const BasicPassword: React.FC<Props> = props => {
  const {
    disabled,
    error,
    errorMessage,
    helpText,
    helpTextPosition = 'below-field',
    helperTextToolTipConfig,
    label,
    required,
    hidden,
    name,
    onBlur,
    onChange,
    placeholder,
    value
  } = props;

  const [uid] = useState(idGenerator());
  const [focused, setFocus] = useState(false);
  const [visible, setVisibilty] = useState(false);

  const toggleVisibility = () => {
    setVisibilty(prevState => !prevState);
  };

  const handleFocus = () => setFocus(true);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocus(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      toggleVisibility();
    }
  };

  return (
    <div
      className={cn('clr-form-control', {
        'clr-form-control-disabled': disabled
      })}
      hidden={hidden}>
      {label && (
        <Label
          label={label}
          required={required}
          enableHelpText={helpTextPosition === 'after-label'}
          helpText={helpText}
          tooltipPosition={
            helperTextToolTipConfig
              ? helperTextToolTipConfig.position
              : undefined
          }
          tooltipSize={
            helperTextToolTipConfig ? helperTextToolTipConfig.size : undefined
          }
          htmlFor={uid}
        />
      )}
      <div className={cn('clr-control-container', { 'clr-error': error })}>
        <div className="clr-input-wrapper">
          <div className={cn('clr-input-group', { 'clr-focus': focused })}>
            <input
              id={uid}
              className="clr-input"
              type={visible ? 'text' : 'password'}
              disabled={disabled}
              placeholder={placeholder}
              name={name}
              value={value}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={onChange}
              title={label === undefined ? placeholder || name : undefined}
            />
            <button
              className="clr-input-group-icon-action"
              type="button"
              onKeyPress={handleKeyPress}>
              <clr-icon
                shape={visible ? 'eye-hide' : 'eye'}
                title={visible ? 'Hide' : 'Show'}
                onClick={toggleVisibility}
              />
            </button>
          </div>
          <clr-icon class="clr-validate-icon" shape="exclamation-circle" />
        </div>
        <span className="clr-subtext">
          {error ? (
            errorMessage
          ) : (
            <>{helpTextPosition === 'below-field' && helpText}</>
          )}
        </span>
      </div>
    </div>
  );
};
