import cn from 'classnames';
import React, { useState } from 'react';
import { idGenerator } from '../common/Utils';
import { TooltipPosition, TooltipSize } from '../tooltip';
import Label from './Label';

interface Props {
  id?: string;
  disabled?: boolean;
  name?: string;
  value?: string;
  label?: string;
  required?: boolean;
  hidden?: boolean;
  helpText?: string | JSX.Element;
  helpTextPosition?: 'after-label' | 'below-field';
  helperTextToolTipConfig?: {
    size?: TooltipSize;
    position?: TooltipPosition;
  };
  error?: boolean;
  errorMessage?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void;
}

export const BasicSelect: React.FC<Props> = props => {
  const {
    id,
    children,
    disabled,
    error,
    errorMessage,
    helpText,
    helpTextPosition = 'below-field',
    helperTextToolTipConfig,
    label,
    required,
    hidden,
    name,
    value,
    onBlur,
    onChange
  } = props;

  const [uid] = useState(idGenerator(id));

  return (
    <div
      className={cn('clr-form-control', {
        'clr-form-control-disabled': disabled
      })}
      hidden={hidden}>
      {label && (
        <Label
          label={label}
          required={required}
          enableHelpText={helpTextPosition === 'after-label'}
          helpText={helpText}
          tooltipPosition={
            helperTextToolTipConfig
              ? helperTextToolTipConfig.position
              : undefined
          }
          tooltipSize={
            helperTextToolTipConfig ? helperTextToolTipConfig.size : undefined
          }
          htmlFor={uid}
        />
      )}
      <div className={cn('clr-control-container', { 'clr-error': error })}>
        <div className="clr-select-wrapper">
          <select
            id={uid}
            className="clr-select"
            disabled={disabled}
            name={name}
            value={value}
            onBlur={onBlur}
            onChange={onChange}>
            {children}
          </select>
          <clr-icon class="clr-validate-icon" shape="exclamation-circle" />
        </div>
        <span className="clr-subtext">
          {error ? (
            errorMessage
          ) : (
            <>{helpTextPosition === 'below-field' && helpText}</>
          )}
        </span>
      </div>
    </div>
  );
};

interface OptionProps {
  value?: any;
  disabled?: boolean;
  title?: string;
}
export const SelectOption: React.FC<OptionProps> = props => (
  <option {...props}>{props.children}</option>
);
