import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Spinner } from '../spinner';
import { DropdownState } from './types';

interface IconData {
  className?: string;
  size?: string;
  shape: string;
}

interface ButtonData {
  family?: 'outline' | 'link';
  size?: 'sm' | 'md';
  type?: 'primary' | 'success' | 'warning' | 'danger';
}

type DropdownPosition =
  | 'bottom-left'
  | 'bottom-right'
  | 'top-left'
  | 'top-right'
  | 'left-bottom'
  | 'left-top'
  | 'right-bottom'
  | 'right-top';

interface DropdownProps {
  state?: DropdownState;
  position?: DropdownPosition;
  onToggleClick: (
    state: DropdownState,
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
  onDismissDropdown: () => void;
}

export interface ToggleProps {
  state?: DropdownState;
  isItem?: boolean;
  icon?: IconData;
  button?: ButtonData;
  disabled?: boolean;
  attrs?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  onToggle?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface MenuProps {
  visible?: boolean;
  onItemClick?: () => void;
}

interface ItemProps {
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onItemClick?: () => void;
  attrs?: React.ButtonHTMLAttributes<HTMLButtonElement>;
}

export const Dropdown: React.FC<DropdownProps> = props => {
  const {
    children,
    state = 'closed',
    position = 'bottom-left',
    onToggleClick,
    onDismissDropdown
  } = props;
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onDismissDropdown();
      }
    },
    [onDismissDropdown]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return function unMount() {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const classNames = cn('dropdown', position, {
    open: state === 'open'
  });

  const onItemClick = () => {
    onDismissDropdown();
  };

  const onToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onToggleClick) {
      onToggleClick(state, event);
    }
  };

  const renderDropdownChildren = () => {
    return React.Children.map(children, child => {
      if (
        React.isValidElement<MenuProps>(child) &&
        child.type === DropdownMenu
      ) {
        return React.cloneElement(child, {
          onItemClick,
          visible: state === 'open'
        });
      } else if (
        React.isValidElement<ToggleProps>(child) &&
        child.type === DropdownToggle
      ) {
        return React.cloneElement(child, {
          onToggle,
          state
        });
      } else {
        return null;
      }
    });
  };

  return (
    <div className={classNames} ref={dropdownRef}>
      {renderDropdownChildren()}
    </div>
  );
};

export const DropdownMenu: React.FC<MenuProps> = props => {
  const { children, visible, onItemClick } = props;
  const menuRef = useRef<HTMLDivElement>(null);
  const [focusableElements, setFocusableElements] = useState<NodeListOf<
    Element
  > | null>();
  const [menuItemIndexInFocus, setMenuItemIndexInFocus] = useState(0);

  useEffect(() => {
    if (!visible) return;
    const focusedElBeforeOpen = document.activeElement;
    let menuEls = menuRef.current && menuRef.current.querySelectorAll('button');
    const firstEl = menuEls && (menuEls[0] as HTMLElement);
    firstEl && firstEl.focus();
    setFocusableElements(menuEls);
    setMenuItemIndexInFocus(0);

    return () => {
      if (props.visible && focusedElBeforeOpen) {
        (focusedElBeforeOpen as HTMLElement).focus();
        return;
      }
    };
  }, [visible]);

  const handleMenuKeyEvt = (event: React.KeyboardEvent) => {
    event.stopPropagation();
    let currentIndex = menuItemIndexInFocus;
    switch (event.keyCode) {
      case 40: //up key
        currentIndex =
          currentIndex === (focusableElements && focusableElements.length - 1)
            ? 0
            : currentIndex + 1;
        event.preventDefault();
        break;
      case 38: //down key
        currentIndex =
          currentIndex === 0
            ? (focusableElements && focusableElements.length - 1) || 0
            : currentIndex - 1;
        event.preventDefault();
        break;
      case 27: //esc
        onItemClick && onItemClick();
        return;
      default:
        return;
    }
    setMenuItemIndexInFocus(currentIndex);

    focusableElements &&
      (focusableElements[currentIndex] as HTMLElement).focus();
  };

  const renderMenuChildren = () => {
    return React.Children.map(children, child => {
      if (
        React.isValidElement<ItemProps>(child) &&
        child.type === DropdownItem
      ) {
        return React.cloneElement(child, {
          onItemClick
        });
      } else {
        return child;
      }
    });
  };

  return visible ? (
    <div
      onKeyDown={handleMenuKeyEvt}
      className="dropdown-menu"
      role="menu"
      ref={menuRef}>
      {renderMenuChildren()}
    </div>
  ) : null;
};

export const DropdownToggle: React.FC<ToggleProps> = props => {
  const {
    state,
    isItem,
    icon,
    button,
    disabled,
    onToggle,
    children,
    attrs
  } = props;

  const getButtonClass = () => {
    const buttonClass = [];
    if (button) {
      const { size, type, family } = button;
      if (size) {
        buttonClass.push(`btn-${size}`);
      }
      if (family === 'outline') {
        if (type === 'primary') {
          buttonClass.push(`btn-${family}`);
        } else {
          buttonClass.push(`btn-${type}-${family}`);
        }
      } else if (family === 'link') {
        buttonClass.push(`btn-${family}`);
      } else {
        buttonClass.push(`btn-${type}`);
      }
    }
    return buttonClass;
  };

  const classNames = cn(
    isItem ? 'dropdown-item expandable' : 'dropdown-toggle',
    {
      btn: button
    },
    { disabled },
    getButtonClass()
  );

  const renderIcon = () => {
    return icon ? (
      <clr-icon
        shape={icon.shape}
        class={icon.className}
        size={icon.size || '18'}
      />
    ) : null;
  };
  return state === 'fetching' ? (
    <Spinner size="sm" inline={true} />
  ) : (
    <button
      className={classNames}
      disabled={disabled}
      type="button"
      aria-haspopup="menu"
      aria-expanded={state === 'open'}
      onClick={onToggle}
      {...attrs}>
      {renderIcon()}
      {children}
    </button>
  );
};

export const DropdownDivider: React.FC = props => {
  return <div className="dropdown-divider" />;
};

export const DropdownHeader: React.FC = props => {
  return <h4 className="dropdown-header">{props.children}</h4>;
};

export const DropdownItem: React.FC<ItemProps> = props => {
  const { children, disabled, onClick, onItemClick, ...attrs } = props;
  const classNames = cn('dropdown-item', { disabled });

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e);
    }
    onItemClick!();
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      tabIndex={-1}
      role="menuitem"
      className={classNames}
      disabled={disabled}
      {...attrs}>
      {children}
    </button>
  );
};
