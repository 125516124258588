export class CspFeatureFlagLoadFailureError extends Error {
    constructor() {
        super('Failed to load feature flags');
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = CspFeatureFlagLoadFailureError.name;
    }
}

export class CspFeatureFlagInvalidSdkKeyError extends Error {
    constructor() {
        super('Provided SDK Key is invalid');
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = CspFeatureFlagInvalidSdkKeyError.name;
    }
}

export class CspFeatureFlagInvalidTokenError extends Error {
    constructor() {
        super('Provided ID Token is invalid');
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = CspFeatureFlagInvalidTokenError.name;
    }
}
