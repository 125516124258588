import cn from 'classnames';
import React, { useState, useEffect, createRef } from 'react';
import { idGenerator } from '../common/Utils';

interface ModalProps {
  visible: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  closable?: boolean;
  nav?: React.ReactElement;
  title: React.ReactElement<TitleProps>;
  body: React.ReactElement;
  footer?: React.ReactElement;
  onCloseClick?: () => void;
  onBackdropClick?: () => void;
}

interface TitleProps {
  closable?: boolean;
  onCloseClick?: () => void;
  id?: string;
}

export const Modal: React.FC<ModalProps> = props => {
  const {
    closable = false,
    visible,
    size = 'md',
    nav,
    title,
    body,
    footer,
    onCloseClick,
    onBackdropClick
  } = props;
  const dialogClass = cn('modal-dialog', { [`modal-${size}`]: size });
  const modalRef: React.RefObject<HTMLInputElement> = createRef();
  const [
    focusableModalElements,
    setFocusableModalElements
  ] = useState<NodeListOf<Element> | null>();
  const [firstElement, setFirstElement] = useState<HTMLElement | null>();
  const [id] = useState(idGenerator());

  const renderModalTitle = () => {
    return React.cloneElement(title, {
      closable,
      onCloseClick,
      id
    });
  };

  useEffect(() => {
    if (!props.visible) return;
    let focusedElBeforeOpen = document.activeElement;
    let modalEls =
      modalRef.current &&
      modalRef.current.querySelectorAll(
        'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
      );
    let firstEl = modalEls && (modalEls[0] as HTMLElement);
    firstEl && firstEl.focus();
    setFocusableModalElements(modalEls);
    setFirstElement(firstEl);

    return () => {
      if (props.visible && focusedElBeforeOpen) {
        (focusedElBeforeOpen as HTMLElement).focus();
        return;
      }
    };
  }, [props.visible]);

  const onKeyDown = (e: React.KeyboardEvent) => {
    e.keyCode === 27 ? handleEscKey(e) : e.keyCode === 9 ? handleTabKey(e) : '';
  };

  const handleEscKey = (e: React.KeyboardEvent) => {
    e.preventDefault();
    onCloseClick && onCloseClick();
  };

  const handleTabKey = (e: React.KeyboardEvent) => {
    const lastElement =
      focusableModalElements &&
      focusableModalElements[focusableModalElements.length - 1];

    if (!e.shiftKey && document.activeElement === lastElement) {
      e.preventDefault();
      firstElement && (firstElement as HTMLElement).focus();
    }

    if (e.shiftKey && document.activeElement === firstElement) {
      e.preventDefault();
      lastElement && (lastElement as HTMLElement).focus();
    }
  };

  return (
    <>
      {visible && (
        <div className="modal" onKeyDown={onKeyDown}>
          <div className={dialogClass} role="dialog" aria-labelledby={id}>
            <div className="modal-content-wrapper">
              {nav}
              <div className="modal-content" ref={modalRef}>
                {renderModalTitle()}
                {body}
                {footer}
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop"
            aria-hidden="true"
            onClick={onBackdropClick}
          />
        </div>
      )}
    </>
  );
};

export const ModalTitle: React.FC<TitleProps> = props => {
  const { children, closable, onCloseClick, id } = props;

  const renderCloseButton = () => {
    return closable ? (
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={onCloseClick}>
        <clr-icon aria-hidden="true" shape="close" />
      </button>
    ) : null;
  };

  return (
    <div className="modal-header">
      {renderCloseButton()}
      <h3 id={id} className="modal-title">
        {children}
      </h3>
    </div>
  );
};

export const ModalBody: React.FC = props => (
  <div className="modal-body">{props.children}</div>
);

export const ModalFooter: React.FC = props => {
  const { children } = props;
  return <nav className="modal-footer">{children}</nav>;
};

export const ModalNav: React.FC = props => {
  const { children } = props;
  return <nav className={cn('modal-nav')}>{children}</nav>;
};
