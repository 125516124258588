import { verifyAndDecodeSdkKey } from './utils';
import { CspFeatureFlagConfig, CspFeatureFlagRetryPolicy } from './model';

// The default timeout for evaluating all the feature flags is 15 seconds, minimal is 1 seconds
const DEFAULT_CONNECTION_TIMEOUT_IN_MILLIS = 15000;
const MINIMAL_CONNECTION_TIMEOUT_IN_MILLIS = 1000;

// The number of retries used when evaluating all flags failed.
const DEFAULT_RETRY_MAX_ATTEMPTS_COUNT = 2;
const MINIMAL_RETRY_MAX_ATTEMPTS_COUNT = 0;

export interface CspFeatureFlagAdjustedConfig {
    cspURL: string;
    sdkKeyValue: string;
    token?: string;
    flagIds?: string[];
    apiTimeoutInMilliseconds: number;
    maxAttempts: number;
    componentName: string;
    disableAnalytics: boolean;
}

/**
 * Extract all configuration items / use default values if not provided
 * @param config provided configuration
 */
export function getAdjustedConfiguration(config: CspFeatureFlagConfig): CspFeatureFlagAdjustedConfig {
    const decodedApiKey: string[] = verifyAndDecodeSdkKey(config.sdkKey);

    const retry: CspFeatureFlagRetryPolicy = config.retryPolicy || {};

    let apiTimeoutInMillis = retry.apiTimeoutInMilliseconds || DEFAULT_CONNECTION_TIMEOUT_IN_MILLIS;

    if (apiTimeoutInMillis < MINIMAL_CONNECTION_TIMEOUT_IN_MILLIS) {
        console.warn('Configured timeout failed to meet the minimum allowed. setting timeout to minimal value of 1 seconds');
        apiTimeoutInMillis = MINIMAL_CONNECTION_TIMEOUT_IN_MILLIS;
    }

    let maxAttempts = retry.maxAttempts || DEFAULT_RETRY_MAX_ATTEMPTS_COUNT;

    if (maxAttempts < MINIMAL_RETRY_MAX_ATTEMPTS_COUNT) {
        console.warn('Configured max attempts failed to meet the minimum allowed. setting timeout to minimal value of 0 retry attempts');
        maxAttempts = MINIMAL_RETRY_MAX_ATTEMPTS_COUNT;
    }

    return {
        cspURL: decodedApiKey[0],
        sdkKeyValue: decodedApiKey[1],
        token: config.token,
        flagIds: config.flagIds,
        apiTimeoutInMilliseconds: apiTimeoutInMillis,
        maxAttempts: maxAttempts,
        componentName: config.componentName || "default",
        disableAnalytics: config.disableAnalytics,
    };
}
