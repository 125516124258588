import cn from 'classnames';
import React, { useState } from 'react';
import { idGenerator } from '../common/Utils';
import { TooltipPosition, TooltipSize } from '../tooltip';
import './BasicCheckbox.css';
import Label from './Label';

interface Props {
  id?: string;
  checked?: boolean;
  name?: string;
  value?: any;
  type?: 'checkbox' | 'toggle';
  disabled?: boolean;
  label?: string | JSX.Element;
  attrs?: React.InputHTMLAttributes<HTMLInputElement>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

interface GroupProps {
  inline?: boolean;
  label?: string;
  required?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  helpText?: string | JSX.Element;
  helpTextPosition?: 'after-label' | 'below-field';
  helperTextToolTipConfig?: {
    size?: TooltipSize;
    position?: TooltipPosition;
  };
}

export const BasicCheckbox: React.FC<Props> = props => {
  const {
    id,
    name,
    checked,
    value,
    disabled,
    label,
    type = 'checkbox',
    onChange,
    onBlur,
    attrs
  } = props;

  const [uid] = useState(idGenerator(id));

  return (
    <div
      className={cn(`clr-${type}-wrapper`, {
        disabled
      })}>
      <input
        className={`clr-${type}`}
        id={uid}
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        {...attrs}
      />
      <label htmlFor={uid} className="clr-control-label">
        {label}
      </label>
    </div>
  );
};

export const BasicCheckboxGroup: React.FC<GroupProps> = props => {
  const {
    children,
    error,
    errorMessage,
    helpText,
    helpTextPosition = 'below-field',
    helperTextToolTipConfig,
    inline,
    label,
    required,
    disabled,
    hidden
  } = props;
  return (
    <div
      className={cn('clr-form-control', {
        'clr-form-control-disabled': disabled
      })}
      hidden={hidden}>
      {label && (
        <Label
          label={label}
          required={required}
          enableHelpText={helpTextPosition === 'after-label'}
          helpText={helpText}
          tooltipPosition={
            helperTextToolTipConfig
              ? helperTextToolTipConfig.position
              : undefined
          }
          tooltipSize={
            helperTextToolTipConfig ? helperTextToolTipConfig.size : undefined
          }
        />
      )}
      <div
        className={cn('clr-control-container', {
          'clr-error': error,
          'clr-control-inline': inline
        })}>
        {children}
        <div className="clr-subtext-wrapper">
          <clr-icon class="clr-validate-icon" shape="exclamation-circle" />
          <span className="clr-subtext">
            {error ? (
              errorMessage
            ) : (
              <>{helpTextPosition === 'below-field' && helpText}</>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};
