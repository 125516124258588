import cn from 'classnames';
import React from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipPosition,
  TooltipSize,
  TooltipTrigger
} from '../tooltip';
import './Label.css';

interface Props {
  htmlFor?: string;
  label?: string;
  required?: boolean;
  enableHelpText?: boolean;
  helpText?: string | JSX.Element;
  tooltipSize?: TooltipSize;
  tooltipPosition?: TooltipPosition;
}

const Label: React.FC<Props> = props => {
  const {
    htmlFor,
    label,
    required = false,
    enableHelpText,
    helpText,
    tooltipSize = 'md',
    tooltipPosition = 'top-right'
  } = props;
  return label ? (
    <label htmlFor={htmlFor} className="clr-control-label">
      <div className="crc-label-content">
        <div className={cn('crc-label-text', { required })}>{label}</div>
        {enableHelpText && helpText && (
          <Tooltip size={tooltipSize} position={tooltipPosition}>
            <TooltipTrigger>
              <clr-icon shape="info-circle" size="20" class="is-info" />
            </TooltipTrigger>
            <TooltipContent>{helpText}</TooltipContent>
          </Tooltip>
        )}
      </div>
    </label>
  ) : null;
};

export default Label;
