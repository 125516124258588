import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button } from '../button';
import { Modal, ModalBody, ModalFooter, ModalTitle } from '../modal';
import { TooltipPosition, TooltipSize } from '../tooltip';
import { TreeNode, TreeNodeItem } from '../tree-view';
import { BasicInput } from './BasicInput';
import './BasicSearchable.css';
import Label from './Label';
import { SearchableCustomEvent } from './types';

interface SearchOption {
  textLabel: string;
  value: string;
}

interface Props {
  name?: string;
  value?: string;
  label?: string;
  required?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  placeholder?: string;
  helpText?: string | JSX.Element;
  helpTextPosition?: 'after-label' | 'below-field';
  helperTextToolTipConfig?: {
    size?: TooltipSize;
    position?: TooltipPosition;
  };
  error?: boolean;
  errorMessage?: string;
  options?: SearchOption[];
  modalDOM?: HTMLDivElement;
  onChange?: (event: SearchableCustomEvent) => void;
  onBlur?: (event: SearchableCustomEvent) => void;
}

export const BasicSearchable: React.FC<Props> = props => {
  const {
    name,
    value,
    label,
    required,
    hidden,
    disabled,
    placeholder,
    helpText,
    helpTextPosition,
    helperTextToolTipConfig,
    error,
    errorMessage,
    options = [],
    modalDOM,
    onChange,
    onBlur
  } = props;

  const domNode = modalDOM || document.body;

  const [selectModal, setSelectModal] = useState(false);
  const [filter, setFilter] = useState<string>();

  const selectedOptionIndex = options.findIndex(o => o.value === value);
  const selectedOption =
    selectedOptionIndex >= 0 ? options[selectedOptionIndex] : undefined;

  const rearrangedOptions = selectedOption
    ? [selectedOption, ...options.filter(o => o.value !== selectedOption.value)]
    : options;

  const filteredOptions = filter
    ? rearrangedOptions
        .filter(o => o.textLabel.toLowerCase().includes(filter.toLowerCase()))
        .sort(
          (a, b) =>
            a.textLabel.toLowerCase().indexOf(filter.toLowerCase()) -
            b.textLabel.toLowerCase().indexOf(filter.toLowerCase())
        )
    : rearrangedOptions;

  const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setFilter(e.target.value);
  };

  const showSelectModal = () => setSelectModal(true);
  const dismissSelectModal = () => {
    setSelectModal(false);
    focusOnElement(value ? 'deselect-item' : 'select-item');
  };

  const focusOnElement = useCallback((id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.focus();
    }
  }, []);

  useEffect(() => {
    if (selectModal) {
      focusOnElement('search-item');
    }
  }, [selectModal, focusOnElement]);

  const deselectItem = () => {
    onItemClick('')();
    focusOnElement('select-item');
  };
  const onItemClick = (v: string) => () => {
    if (onChange) {
      const event: SearchableCustomEvent = { target: { name, value: v } };
      onChange(event);
    }
    dismissSelectModal();
  };

  const handleBlur = () => {
    if (onBlur) {
      const event: SearchableCustomEvent = { target: { name } };
      onBlur(event);
    }
  };

  const renderModalTitle = () => <ModalTitle>{label}</ModalTitle>;

  const renderModalBody = () => (
    <ModalBody>
      <div className="item-filter">
        <BasicInput
          id="search-item"
          name="filter"
          value={filter}
          onChange={onFilterChange}
          placeholder="Search.."
        />
      </div>
      {filteredOptions && filteredOptions.length > 0 ? (
        <div className="item-options-container">
          <label className="item-options-label">Select an option</label>
          <div className="item-options">
            {filteredOptions.map((o, i) => {
              return (
                <TreeNode
                  key={o.value + i}
                  expandable={false}
                  content={
                    <TreeNodeItem
                      clickable={true}
                      active={o.value === value}
                      onClick={onItemClick(o.value)}>
                      <div className="item-option">
                        <span className="item-option-label" title={o.textLabel}>
                          {o.textLabel}
                        </span>
                        {o.value === value && (
                          <clr-icon
                            shape="check-circle"
                            size="20"
                            class="is-info is-solid"
                          />
                        )}
                      </div>
                    </TreeNodeItem>
                  }
                />
              );
            })}
          </div>
        </div>
      ) : (
        <div className="no-options">
          <clr-icon
            shape="exclamation-circle"
            size="24"
            class="is-info is-solid"
          />
          No options available
        </div>
      )}
    </ModalBody>
  );

  const renderModalFooter = () => (
    <ModalFooter>
      <Button onClick={dismissSelectModal}>Close</Button>
    </ModalFooter>
  );

  return (
    <div className="crc-searchable">
      <div
        className={cn('clr-form-control', {
          'clr-form-control-disabled': disabled
        })}
        hidden={hidden}>
        {label && (
          <Label
            label={label}
            required={required}
            enableHelpText={helpTextPosition === 'after-label'}
            helpText={helpText}
            tooltipPosition={
              helperTextToolTipConfig
                ? helperTextToolTipConfig.position
                : undefined
            }
            tooltipSize={
              helperTextToolTipConfig ? helperTextToolTipConfig.size : undefined
            }
          />
        )}
        <div className={cn('clr-control-container', { 'clr-error': error })}>
          <div className="clr-input-wrapper">
            <Button
              id="select-item"
              family="link"
              size="sm"
              class="primary"
              disabled={disabled}
              title={selectedOption && selectedOption.textLabel}
              onClick={showSelectModal}
              onBlur={handleBlur}>
              {(selectedOption && selectedOption.textLabel) || placeholder}
            </Button>
            <clr-icon class="clr-validate-icon" shape="exclamation-circle" />
            {selectedOption && (
              <Button
                id="deselect-item"
                family="link"
                size="sm"
                class="primary"
                disabled={disabled}
                title="Deselect"
                onClick={deselectItem}>
                <clr-icon shape="times-circle" size="16" />
              </Button>
            )}
          </div>
          <span className="clr-subtext">
            {error ? (
              errorMessage
            ) : (
              <>{helpTextPosition === 'below-field' && helpText}</>
            )}
          </span>
        </div>
      </div>
      {ReactDOM.createPortal(
        <div className="crc-searchable-modal">
          <Modal
            size="lg"
            visible={selectModal}
            closable={true}
            title={renderModalTitle()}
            body={renderModalBody()}
            footer={renderModalFooter()}
            onCloseClick={dismissSelectModal}
            onBackdropClick={dismissSelectModal}
          />
        </div>,
        domNode
      )}
    </div>
  );
};
