import cn from 'classnames';
import React from 'react';
import './Button.css';

interface Props {
  id?: string;
  title?: string;
  icon?: boolean;
  block?: boolean;
  spinner?: boolean;
  size?: ButtonSize;
  type?: ButtonType;
  class?: ButtonClass;
  family?: ButtonFamily;
  disabled?: boolean;
  hidden?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
  attrs?: React.ButtonHTMLAttributes<HTMLButtonElement>;
}

type ButtonSize = 'sm' | 'md';
type ButtonType = 'button' | 'submit';
type ButtonClass = 'primary' | 'success' | 'warning' | 'danger';
type ButtonFamily = 'outline' | 'link';

const Spinner = () => (
  <span className="spinner spinner-inline m-r-0-3">Loading...</span>
);

const Button: React.FC<Props> = props => {
  const {
    id,
    title,
    size,
    children,
    onClick,
    family,
    disabled,
    hidden,
    icon,
    block,
    spinner,
    onBlur,
    attrs
  } = props;
  let { type } = props;
  type = type ? type : 'button';
  const getButtonClass = () => {
    const buttonClass = [];
    if (size) {
      buttonClass.push(`btn-${size}`);
    }
    if (family === 'outline') {
      if (props.class === 'primary') {
        buttonClass.push(`btn-${family}`);
      } else {
        buttonClass.push(`btn-${props.class}-${family}`);
      }
    } else if (family === 'link') {
      buttonClass.push(`btn-${family}`);
    } else {
      buttonClass.push(`btn-${props.class}`);
    }
    return buttonClass;
  };

  const classNames = cn('btn', getButtonClass(), {
    'btn-icon': icon,
    'btn-block': block
  });

  return (
    <button
      id={id}
      title={title}
      className={classNames}
      onClick={onClick}
      hidden={hidden}
      disabled={disabled}
      type={type}
      onBlur={onBlur}
      {...attrs}>
      {spinner ? <Spinner /> : null}
      {children}
    </button>
  );
};

export default Button;
